import React, { useState, useEffect } from 'react';
import { Edit, SimpleForm, SelectInput, NumberInput } from 'react-admin';

export default ({ permissions, ...props }) => {
  let [isCountrySelectionsUpdated, setIsCountrySelectionsUpdated] = useState(false)
  let [countrySelections, setCountrySelections] = useState(
    [
      { code: 'US', pretty_name: 'United States' },
      { code: 'CN', pretty_name: 'China' },
      { code: 'JP', pretty_name: 'Japan' }
    ]
  )

  useEffect(
    () => {
      const token = localStorage.getItem('access_token');
      const request = new Request(`${process.env.NODE_ENV === 'production' ? 'https://manager-api.vpn365lab.com' : 'http://localhost:8088'}/countries`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(( countries ) => {
          setIsCountrySelectionsUpdated(true)
          setCountrySelections(countries)
        });
    },
    [isCountrySelectionsUpdated]
  );

  return (
    <Edit {...props}>
        <SimpleForm>
          <SelectInput label="Country" source="country" optionText="pretty_name" optionValue="code" disabled choices={countrySelections} />
          <NumberInput label="Rate Limit for VIP" source="rate_limit_vip" />
          <NumberInput label="Rate Limit for Free" source="rate_limit_free" />
        </SimpleForm>
    </Edit>
  )
}
