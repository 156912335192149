import React from 'react';
import { List, Datagrid, TextField, BooleanField, NumberField, EditButton } from 'react-admin';

export default ({ ...props }) => (
    <List {...props}>
        <Datagrid>
          <TextField label="Subdomain" source="subdomain" />
          <TextField label="Data Center" source="meta.data_center" />
          <TextField label="City Code" source="meta.node_city_code" />
          <TextField label="Optimized Country" source="meta_override.node_optimized_country" />
          <NumberField label="Node Weight" source="meta_override.node_weight" />
          <BooleanField label="Published" source="meta_override.node_published" />
          <BooleanField label="In Fastest" source="meta_override.node_in_fastest" />
          <BooleanField label="In Free" source="meta_override.node_in_free" />
          <BooleanField label="For IOS" source="meta_override.node_for_ios" />
          <BooleanField label="For Mac" source="meta_override.node_for_mac" />
          <EditButton />
        </Datagrid>
    </List>
);
