import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput } from 'react-admin';

export default ({ permissions, ...props }) => {
  return (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Optimized Country" source="meta_override.node_optimized_country" />
            <NumberInput label="Node Weight" source="meta_override.node_weight" />
            <BooleanInput label="Published" source="meta_override.node_published" />
            <BooleanInput label="In Fastest" source="meta_override.node_in_fastest" />
            <BooleanInput label="In Free" source="meta_override.node_in_free" />
            <BooleanInput label="For IOS" source="meta_override.node_for_ios" />
            <BooleanInput label="For Mac" source="meta_override.node_for_mac" />
        </SimpleForm>
    </Edit>
  )
}
