import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton } from 'react-admin';
import MultipliedDateField from '../../../components/MultipliedDateField';

export default ({ ...props }) => (
    <List {...props}>
        <Datagrid>
          <TextField label="Country" source="country.pretty_name" />
          <NumberField label="Rate Limit for VIP" source="rate_limit_vip" />
          <NumberField label="Rate Limit for Free" source="rate_limit_free" />
          <MultipliedDateField label="Created At" source="created_at"  />
          <MultipliedDateField label="Updated At" source="updated_at"  />
          <EditButton />
        </Datagrid>
    </List>
);
