import React from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import AuthProvider from './components/AuthProvider';
import './App.css';
import NodesList from './resources/Nodes/List';
import NodesEdit from './resources/Nodes/Edit';
import RateLimitsList from './resources/RateLimits/List';
import RateLimitsEdit from './resources/RateLimits/Edit';
import RateLimitsCreate from './resources/RateLimits/Create';

console.log(process.env.NODE_ENV)

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('access_token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}
const dataProvider = jsonServerProvider(
  `${process.env.NODE_ENV === 'production' ? 'https://manager-api.vpn365lab.com' : 'http://localhost:8088'}`,
  httpClient
)

const App = () => (
  <Admin dataProvider={ dataProvider } authProvider={AuthProvider}>
      <Resource name="nodes" options={{ label: 'Nodes' }} list={NodesList} edit={NodesEdit} />
      <Resource name="rate_limits" options={{ label: 'Rate Limits' }} list={RateLimitsList} edit={RateLimitsEdit} create={RateLimitsCreate} />
  </Admin>
);

export default App;
